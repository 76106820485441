import React from "react";
import { Scan, CodeScanPayload } from "common";

interface Props {
  scan: Scan;
  title: string;
  onCodeScanClick: (id: number) => void;
}

export default function CodeScanTab({ scan, onCodeScanClick }: Props) {
  return (
    <>
      {scan.Targets.map((target, targetId) => {
        if (target.Type !== "CodeScan") {
          return <></>;
        }
        const codescan = target as CodeScanPayload;

        return (
          <div className="max-w-xl p-6 rounded-lg shadow">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Report for {codescan.Repository}
            </h5>
            <p className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white"></p>

            <button
              onClick={() => onCodeScanClick(targetId)}
              className="inline-flex items-center mt-4 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Download Codescan Report
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </div>
        );
      })}
    </>
  );
}
