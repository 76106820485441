import Icon from "@mdi/react";
import React from "react";
import { useNavigate } from "react-router-dom";
interface Props {
  items: {
    text: string;
    link: string;
    iconPath?: string;
  }[];
}

export default function BreadCrumbs({ items }: Props) {

  const navigate = useNavigate();

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-4 md:space-x-2">
        {items.map((item, index) => (
          <>
            <li className="inline-flex items-center">
              <button
                onClick={() => navigate(item.link)}
                className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-indigo-400 dark:hover:text-white"
              >
                {item.iconPath && <Icon path={item.iconPath} size={1} className="pr-1"/>}
                {item.text} 
                
              </button>

              {index < items.length - 1 && (
                <svg
                  className="rtl:rotate-180 w-2 h-2 text-indigo-200 mx-1 ml-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              )}
            </li>
          </>
        ))}
      </ol>
    </nav>
  );
}
