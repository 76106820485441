import { ApexOptions } from "apexcharts";
import { Scan } from "common";
import * as React from "react";
import Chart from "react-apexcharts";
import { colorCodes } from "../../ColorCodes";

interface Props {
  scan: Scan;
}
export default function FindingSeverityRadialGraph({ scan }: Props) {
  if (!scan || !scan.Digest) {
    return <></>;
  }

  const total = Object.entries(scan.Digest).reduce((accumulator, entry) => {
    return accumulator + entry[1];
  }, 0);

  const state: { options: ApexOptions; series: number[] } = {
    series: Object.keys(colorCodes).map(
      (severityTitle) => (scan.Digest![severityTitle] * 100 / total)
    ).map( fraction => Number(fraction)),
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          track: {
            background: '#223'
          },
          dataLabels: {
            name: {
              fontSize: "22px",
              color: "#aaa",
              
            },
            value: {
              fontSize: "16px",
              formatter: (w: number) => (w/100 * total).toString(),              color: "#aaa",
            },
            total: {
              show: true,
              label: "Total",
              color: "#aaa",
              formatter: (w: number) => {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                if (!scan.Digest) {
                  return "None";
                }
                return Object.entries(scan.Digest)
                  .reduce((accumulator, entry) => {
                    return accumulator + entry[1];
                  }, 0)
                  .toString();
              },
            },
          },
        },
      },
      labels: Object.keys(colorCodes),
      colors: Object.keys(colorCodes).map((c) => colorCodes[c].asHex),
    },
  };

  return (
    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg dark:border-gray-800 rounded-lg bg-gradient-to-bl from-orange-900 from-1% via-gray-900 via-25% to-gray-900 to-90% shadow-sm shadow-gray-800">
      <a href="#">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-400">
          Latest Infrastructure Scan Findings
        </h5>
      </a>
      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
        Scan findings from Latest Scan on {new Date(Date.parse(scan.CreatedAt)).toTimeString()}.
      </p>

      <Chart
        options={state.options}
        series={state.series}
        type="radialBar"
        height={350}
      />
    </div>
  );
}
