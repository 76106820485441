import React from "react";
import CtaButton from "./CtaButton";
import { ReactComponent as Logo } from '../../logo.svg';

export default function Hero() {
  return (
    <div className="h-screen bg-gradient-to-br from-indigo-900 from-1% via-gray-900 via-15% to-black to-90%">
      <div className="relative isolate flex flex-col items-center justify-center h-full px-6 pt-14 lg:px-8">
        {/* Shield Container */}
        <div
          id="shield-container"
          className="absolute flex justify-center items-center rotating-container"
        >
          
          {/* Energy Shield Circle (Left Side) */}
          <div className="w-[480px] h-[480px] rounded-full bg-gradient-to-tl from-indigo-600 via-transparent to-transparent opacity-50 blur-lg animate-smoke-left absolute -translate-x-1/2 -translate-y-1/4"></div>
          {/* Energy Shield Circle (Right Side) */}
          <div className="w-[480px] h-[480px] rounded-full bg-gradient-to-br from-gray-500 via-transparent to-transparent opacity-50 blur-lg animate-smoke-right absolute translate-x-1/2 translate-y-1/4"></div>
        </div>

        {/* Text container to keep text static and centered */}
        <div className="text-center relative z-10 items-center">
          <h1 className="text-balance text-5xl font-semibold tracking-tight text-indigo-500 sm:text-7xl">
            ThreatScope
          </h1>
      <div className="relative isolate flex flex-col items-center justify-center pt-5 lg:px-8">
          <Logo className="w-10 h-10 text-indigo-300" />
          </div>

          <p className="mt-4 text-pretty text-lg font-medium text-indigo-200 sm:text-xl/8">
            Cloud Security for All Sizes
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <CtaButton text="Get Started" link={"/home"} />
            <a
              href="#purpose"
              className="text-sm font-semibold leading-6 text-indigo-300"
            >
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>

      {/* Add the CSS animations */}
      <style jsx>{`
        @keyframes smoke-left {
          0% {
            transform: scale(1);
            opacity: 0.5;
          }

          50% {
            transform: scale(1.05);
            opacity: 0.7;
          }
          75% {
            transform: scale(1.1);
            opacity: 0.6;
          }

          100% {
            transform: scale(1);
            opacity: 0.5;
          }
        }

        @keyframes smoke-right {
          0% {
            transform: scale(1);
            opacity: 0.5;
          }
          25% {
            transform: scale(1.1);
            opacity: 0.6;
          }
          50% {
            transform: scale(1.05);
            opacity: 0.7;
          }
          100% {
            transform: scale(1);
            opacity: 0.5;
          }
        }

        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .animate-smoke-left {
          animation: smoke-left 4s ease-in-out infinite;
        }

        .animate-smoke-right {
          animation: smoke-right 4s ease-in-out infinite;
        }

        .rotating-container {
          animation: rotate 10s linear infinite; /* Adjust duration for rotation speed */
        }
      `}</style>
    </div>
  );
}
