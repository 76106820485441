import AuthService from "../../../service/AuthService";

interface Props {
  authService: AuthService;
  buyService: any; //BuyService
}

function Buy({ authService, buyService }: Props) {
  return (
    <>
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        action={`${buyService.url}/create-checkout-session`}
        method="POST"
      >
        <input type="hidden" name="priceId" value="price_1Q0Z2FIQcZG9pbfjbdrNgZJN" />
        <button type="submit">Checkout</button>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Buy
          </button>
        </div>
      </form>
    </>
  );
}

export default Buy;
