import { useNavigate, Outlet } from "react-router-dom";
import AuthService from "../../../service/AuthService";
import { useEffect, useState } from "react";

interface Props {
  authService: AuthService;
}

export default function AuthLayout({ authService }: Props) {
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await authService.getUser();
        navigate("/home");
      } catch (error) {
      } finally {
        setLoaded(true);
      }
    };
    checkAuth();
  });
  // Login if we are already authenticated

  return <>{loaded && <Outlet />}</>;
}
