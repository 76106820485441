import { Scan } from "common/src/types/Scan";
import AuthService from "./AuthService";
import axios from "axios";

export default class ScanService {
  uri: String;
  authService: AuthService;
  constructor(authService: AuthService, uri: String) {
    this.uri = uri;
    this.authService = authService;
  }

  async submit(scan: Scan) {
    const token = await this.authService.getToken();
    return await axios.post(`${this.uri}/scan`, scan, {
      headers: {
        Authorization: token as unknown as string,
      },
    });
  }

  async list(): Promise<Scan[]> {
    const token = await this.authService.getToken();
    const response = await axios.get(`${this.uri}/scan/`, {
      headers: {
        Authorization: token as unknown as string,
        "Content-Type": "application/json",
      },
    });
    return response.data as Scan[];
  }


  async get(id: number): Promise<Scan> {
    const token = await this.authService.getToken();

    const response = await axios.get(`${this.uri}/scan/${id}`, {
      headers: {
        Authorization: token as unknown as string,
        "Content-Type": "application/json",
      },
    });

    return response.data as Scan;
  }
}
