import { Writable } from "stream";
import { parse as csvParse } from "csv-parse"; // CSV parsing library

export class ProwlerComplianceAdapter {
  parser: any;

  constructor() {
    this.parser = csvParse({
      columns: true,
      delimiter: ';',
      relax_column_count: true, // Allows rows with varying column lengths
    });
  }

  getPipe() {
    return this.parser as Writable;
  }

  onDataIngest(data: { [x: string]: any }) {
    return data["STATUS"];
  }
}
