import { Writable } from "stream";
import DataAdapter from "./DataAdapter";
import { parse as csvParse } from "csv-parse"; // CSV parsing library
import { ReportItem } from "./ReportItem";
import { randomUUID } from "crypto";

export class OpenvasAdapter implements DataAdapter {
  parser: any;

  constructor() {
    this.parser = csvParse({ columns: true });
  }

  getPipe() {
    return this.parser as Writable;
  }

  getRisk(risk: string) {
    return {
      Critical: 10,
      High: 8,
      Medium: 5,
      Low: 3,
      Log: 1,
    }[risk];
  }

  onDataIngest(customerId: string, reportId: number, targetId: number, data: { [x: string]: any }){
    const item: ReportItem = {
      CustomerAndReportId: `${customerId}#${reportId}`,
      CustomerReportIdAndScanType: `${customerId}#${reportId}#Network`,
      CustomerId: customerId,
      ReportId: reportId,
      TargetId: targetId,
      ResourceIdentifier: data["Hostname"],
      ResourceType: "Host",
      Id: randomUUID(),
      Title: data["NVT Name"],
      Description: data["Summary"],
      Severity: data["Severity"],
      RiskScore: this.getRisk(data["Severity"]) || 0,
      ComplianceImpact: '',
      Remediation: data["Solution"],
      Cves: [],
      DetectedAt: new Date().getTime(),
      status: "Open",
      context: data["Specific Result"],
    };
    return item;
  }
}
