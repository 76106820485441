import React, { useEffect, useState } from "react";
import NavButton from "../landing/NavButton";
import Purpose from "../landing/Purpose";
import CtaButton from "../landing/CtaButton";
import Features from "../landing/Features";
import Hero from "../landing/Hero";
import "../landing/Landing.css";
import ContactUs from "../landing/ContactUs";
import { ReactComponent as Logo } from "../../logo.svg";

export default function Landing() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const menuItems = [
    { text: "Purpose", link: "#purpose" },
    { text: "Features", link: "#features" },
    // { text: "Pricing", link: "#pricing" },
  ];

  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      // Show navbar if scrolled down more than 50px
      if (window.scrollY > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`fixed top-0 left-0 w-full transition-opacity bg-slate-950 duration-300 z-50 ${isVisible ? "opacity-100" : "opacity-0"}`}
      >
        <div className="w-fit h-10 flex absolute left-20 top-4">
          <Logo className="w-full h-full bg-clip-text bg-gradient-to-r text-indigo-300" />
          <h4 className="text-white pl-2 pt-2"> ThreatScope</h4>
        </div>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                onClick={toggleMobileMenu} // Add this line to toggle the menu
                className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded={isMobileMenuOpen} // Reflect the state
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`${isMobileMenuOpen ? "hidden" : "block"} h-6 w-6`} // Show/hide based on menu state
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
                <svg
                  className={`${isMobileMenuOpen ? "block" : "hidden"} h-6 w-6`} // Show/hide based on menu state
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  {menuItems.map((m, index) => (
                    <NavButton
                      key={index}
                      text={m.text}
                      link={m.link}
                      onClick={() => {}}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="relative ml-3">
                <div>
                  <CtaButton text="Contact" link="#contact" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="sm:hidden" id="mobile-menu">
            <div className="flex flex-col space-y-4 p-4 bg-slate-800">
              {menuItems.map((m, index) => (
                <NavButton
                  key={index}
                  text={m.text}
                  link={m.link}
                  onClick={() => setIsMobileMenuOpen(false)}
                />
              ))}
            </div>
          </div>
        )}
      </nav>

      <div className="overflow-x-hidden ">
        <Hero />
        <Purpose />

        <Features />

        <ContactUs />

        <footer className="footer bg-slate-950 text-white py-6">
          <div className="container mx-auto text-center">
            <span className="text-violet-400 space-x-10 mb-10">
              <a target="_blank" href="/terms-of-service">
                Terms of Service
              </a>
              <a target="_blank" href="/privacy-policy">
                Privacy Policy
              </a>
            </span>
            <p className="text-gray-400 pt-5">
              &copy; Bytebelt LLC 2024. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
