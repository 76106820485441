import React from "react";

export default function PrivacyPolicy() {
  return <>
  
  <div className="max-w-3xl mx-auto p-6 bg-slate-800 shadow-md rounded-lg text-gray-300">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy for ThreatScope</h1>
      <p className="text-sm text-gray-200 mb-6">Last Updated: 2024-11-12</p>
      <p className="mb-4">
        ThreatScope respects your privacy and is committed to protecting the
        information you share with us. This Privacy Policy explains how we
        collect, use, share, and protect your personal information when you use
        our introduction management software. By using ThreatScope, you consent to the
        practices outlined in this Privacy Policy.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        1. Information We Collect
      </h2>
      <p className="mb-4">
        To provide our services, we collect certain information from you when
        you connect your Cloud Infrastructure and Code to ThreatScope. This includes:
      </p>
      <ul className="list-disc list-inside ml-4 mb-4">
        <li>
          <strong>Repository Access:</strong> We download your repository, and perform scans on it. Afterwards, it is deleted.
          We do not keep your code.
        </li>
        <li>
          <strong>Authentication and Authorization for Cloud Infrastructure:</strong> We use Cloud IAM construts to allow authorization, and authentication of your cloud infrastructure.
        </li>
        <li>
          <strong>Security Findings:</strong> Any Security Findings from our Scanner, including ips from public endpoints, and potential security vulnerabilities.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        2. How We Use Your Information
      </h2>
      <p className="mb-4">
        The information we collect is used solely to deliver the ThreatScope service
        effectively and in line with user expectations. Specifically, we use
        your data to:
      </p>
      <ul className="list-disc list-inside ml-4 mb-4">
        <li>
          Display to the user their security findings.
        </li>
        <li>
          Map findings to Compliance Frameworks.
        </li>
      </ul>
      <p className="mb-4 italic">
        Note: We do not release your information to third parties for advertising, marketing, or
        other unrelated purposes.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        3. Data Sharing and Disclosure
      </h2>
      <p className="mb-4">
        <strong>Third-Party Services:</strong> We do not sell, lease, or share
        your information with third parties for marketing or advertising
        purposes. Your data is used only for displaying security findings as part
        of the ThreatScope service.
      </p>
      <p className="mb-4">
        <strong>Legal Requirements:</strong> We may disclose your information if
        required to do so by law or in response to valid requests by public
        authorities (e.g., a court or government agency).
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        4. Data Security and Retention
      </h2>
      <p className="mb-4">
        We prioritize the security of your data and apply industry-standard
        practices to protect it. We retain your data only as long as necessary
        to fulfill the purposes outlined in this policy or as required by law.
      </p>
    </div>
  </>;
}
