import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../service/AuthService";
import FormInput from "../../FormInput";
import LogoHeader from "../../LogoHeader";

interface Props {
  authService: AuthService;
}
export default function Login({ authService }: Props) {
  const [agreementError, setAgreementError] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [requiresNewPassword, setRequiresNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const navigate = useNavigate();

  const onChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };
  const onChangePassword = (event: any) => {
    setPassword(event.target.value);
  };

  const onChangeNewPassword = (event: any) => {
    setNewPassword(event.target.value);
  };

  const onClickSubmit = (event: any) => {
    event.preventDefault();

    if (!requiresNewPassword) {
      authService.signIn(
        email,
        password,
        (obj) => navigate("/home"),
        (attr, reqs) => setRequiresNewPassword(true)
      );
    } else {
      if (!agreed) {
        setAgreementError(true);
        return;
      }

      authService.newPassword(email, password, newPassword, (obj) =>
        navigate("/home")
      );
    }
  };

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-950">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <LogoHeader />
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                {requiresNewPassword
                  ? "Create Your New Password"
                  : "Log in to your account"}
              </h1>
              <form className="space-y-4 md:space-y-6" action="#">
                <FormInput
                  onChange={onChangeEmail}
                  disabled={requiresNewPassword}
                  label="Your Email"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="kermit@sesamestreet.com"
                />
                <FormInput
                  onChange={onChangePassword}
                  disabled={requiresNewPassword}
                  label="Your Password"
                  id="password"
                  type="password"
                  name="password"
                  placeholder="••••••••"
                />
                {requiresNewPassword && (
                  <>
                    <div
                      className={`flex items-center mb-4 p-2 rounded ${
                        agreementError
                          ? "border border-red-500"
                          : "border border-transparent"
                      }`}
                    >
                      <input
                        type="checkbox"
                        checked={agreed}
                        onClick={() => setAgreed(!agreed)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        I agree to Threatscopes{" "}
                        <a className="text-blue-400" href="/privacy-policy" target="_blank">
                          Privacy Policy
                        </a>
                        {", "}
                        <a className="text-blue-400" href="/software-agreement" target="_blank">
                          Software Agreement
                        </a>
                        {", and "}
                        <a className="text-blue-400" href="/terms-of-service" target="_blank">
                          Terms of Service
                        </a>
                      </label>
                    </div>
                    {agreementError && (
                      <>
                        <p className="text-red-500 text-xs italic">
                          You must agree to the terms to use ThreatScope
                        </p>
                      </>
                    )}
                    <FormInput
                      onChange={onChangeNewPassword}
                      label="Your New Password"
                      id="new-password"
                      type="password"
                      name="n-ewpassword"
                      placeholder="••••••••"
                    />
                  </>
                )}
                {!requiresNewPassword && (
                  <div className="flex items-center justify-between">
                    <a
                      href="/forgot-password"
                      className="text-sm font-medium text-indigo-600 hover:underline dark:text-indigo-100"
                    >
                      Forgot password?
                    </a>
                  </div>
                )}

                <button
                  onClick={onClickSubmit}
                  type="button"
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
                >
                  Log in
                </button>
                {!requiresNewPassword && (
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Don’t have an account yet?{" "}
                    <a
                      href="/signup"
                      className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                    >
                      Sign up
                    </a>
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
