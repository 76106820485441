import { Writable } from "stream";
import { OpenvasAdapter } from "./OpenvasAdapter";
import { AshAdapter } from "./AshAdapter";
import { ProwlerAdapter } from "./ProwlerAdapter";
import { ReportItem } from "./ReportItem";

/**
 * DataAdapter is a way we can take information and transform it for a
 * report.
 */
export default interface DataAdapter {
  getPipe: () => Writable;
  onDataIngest: (customerId: string, reportId: number, targetId: number, data: {
    [x: string]: any;
  }) => ReportItem | undefined;
}

/**
 * Expose Adapters
 */
export const SupportedAdapters = {
  Openvas: new OpenvasAdapter(),
  Ash: new AshAdapter(),
  Prowler: new ProwlerAdapter(),
};
