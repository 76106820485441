import React, { useEffect, useRef, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiCashCheck,
  mdiLightbulb,
  mdiShield,
  mdiThumbsUpDownOutline,
  mdiRadar,
} from "@mdi/js";

export default function Features() {
  return (
    <section id="features" className="relative bg-slate-950 lg:mb-0 lg:h-screen mb-16 flex items-center">

      {/* Glowing Background */}
      <div className="absolute inset-0 flex justify-center items-center">
        <div className="rounded-lg bg-indigo-400 opacity-10 z-50"   style={{ width: "66%", height: "33%", filter: "blur(100px)" }}  />
        
      </div>

      <div className="relative isolate px-6 pt-14 lg:px-8 w-full">
        <div className="mx-auto max-w-7xl pt-8">
          <h2 className="text-3xl font-bold text-center text-indigo-100 mb-8">Features</h2>

          {/* First Row: 3 Feature Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 md:gap-24 justify-items-center">
            <FeatureCard
              title={"Affordable"}
              icon={mdiCashCheck}
              description={"Security shouldn't break the bank."}
            />
            <FeatureCard
              title={"Simple"}
              icon={mdiLightbulb}
              description={"Guaranteed onboarding in under 30 minutes."}
            />
            <FeatureCard
              title={"Appsec"}
              icon={mdiShield}
              description={"Get a breakdown of potential threats in your codebase."}
            />
          </div>

          {/* Second Row: 2 Feature Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 md:gap-24 justify-items-center mt-12">
            <FeatureCard
              title={"Compliance"}
              icon={mdiThumbsUpDownOutline}
              description={"Build a roadmap of achieving compliances like SOC, HIPAA, etc."}
            />
            <FeatureCard
              title={"External Scanning"}
              icon={mdiRadar}
              description={"Scan your external endpoints for vulnerabilities."}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

interface FeatureCardProps {
  title: string;
  icon: string;
  description: string;
}

function FeatureCard({ title, icon, description }: FeatureCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once visible
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the card is visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current); // Clean up observer on unmount
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`block max-w-sm p-6 bg-white rounded-lg shadow-lg dark:bg-gray-900 dark:border-gray-800 transition-opacity duration-700 ${
        isVisible ? "opacity-100 animate-fade-in" : "opacity-0"
      }`}
    >
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
        {title}
      </h5>
      <div className="h-2 border-b-4 border-indigo-400 rounded-sm mx-10 mb-4" />
      {/* Center the icon and apply gradient */}
      <div className="flex flex-col items-center justify-center text-center mx-auto mb-4">
        <Icon path={icon} size={1.4} className="text-blue-500" />
      </div>
      <p className="font-normal text-gray-700 dark:text-gray-400 text-center">{description}</p>
    </div>
  );
}
