export * from "./types/Scan";
export * from './types/Severity'
export * from "./types/adapter/ReportItem";

// Export schemas
export { default as ScanSchema } from "./schemas/scan/Scan.json";
export { default as InfraScanSchema } from "./schemas/scan/InfraScanPayload.json";
export { default as CodeScanSchema } from "./schemas/scan/CodeScanPayload.json";
export { default as CloudProviderSchema } from "./schemas/scan/CloudProvider.json";
export { default as ComplianceResultSchema } from "./schemas/scan/ComplianceResult.json";

export * from "./types/compliance/ComplianceType";
export * from "./types/Validator";
export * from "./types/AggregatedReportRequestProps"

// Declare conditional exports
let conditionalExports: any = {
  Adapters: {},
};

// Check if we are in a Node.js environment
if (typeof window === 'undefined') {
  // We're in a Node.js environment
  conditionalExports.ReportController = require("./types/ReportController").default;
  conditionalExports.ScanController = require("./types/ScanController").default;
  conditionalExports.Adapters = require("./types/adapter/DataAdapter").default;
}

// Export the conditional exports at the end
export const { ReportController, ScanController, Adapters } = conditionalExports;
