import { mdiRadar } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

export default function LogoHeader() {
  return (
    <>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <Icon path={mdiRadar} className="text-indigo-300" size={1}/>
          <span className="pl-2 text-indigo-300">ThreatScope</span>
        </a>
      </div>
    </>
  );
}
