import React from "react";
import FormInput from "../../../FormInput";
import Icon from "@mdi/react";
import { mdiCloseCircle } from "@mdi/js";

interface Props {
  onChangePersonalAccessToken: (event: any) => void;
  onClose: () => void;
  onChangeRepo: (event: any) => void;
  errors?: any[];
}

export default function CodeScan({
  onChangeRepo,
  onChangePersonalAccessToken,
  onClose,
  errors,
}: Props) {
  console.log(errors);
  return (
    <>
      <div className="grid grid-cols-2 items-center">
        <h3 className="text-3xl font-bold dark:text-white">Code Scan</h3>
        <div className="flex justify-end">
        <button 
        onClick={() => onClose()}
        className="mx-auto flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-red-400 sm:mx-0 ">
                  <Icon path={mdiCloseCircle} size={1.0} color={""} />
                  
                </button>
        </div>
      </div>

      <FormInput
        onChange={onChangePersonalAccessToken}
        type="text"
        id="personalAccessToken"
        name="personalAccessToken"
        label="Personal Access Token"
        placeholder="github_ey1v390A"
        error={
          errors && errors.find((e) => e.fieldName === "PersonalAccessToken")
        }
      />

      <FormInput
        onChange={onChangeRepo}
        type="text"
        id="repo"
        name="repo"
        label="Repository"
        placeholder="georgeconstanza/vandelay"
        error={errors && errors.find((e) => e.fieldName === "Repository")}
      />
    </>
  );
}
