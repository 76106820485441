import AuthService from "../../../service/AuthService";
import { useNavigate, useSearchParams } from "react-router-dom";
import FormInput from "../../FormInput";
import LogoHeader from "../../LogoHeader";
import { useState } from "react";

interface Props {
  authService: AuthService;
}
export default function VerifyAccount({ authService }: Props) {

  const [code, setCode] = useState('');

  const onChangeCode = (event: any) => {
    setCode(event.target.value);
  }

  const submitSignup = async () => {
    await authService.confirmSignUp(email, code);
    navigate('/login')
  }

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // You somehow got to this page without being redirected? Send em back.
  if (!searchParams.has("email")) {
    navigate("/login");
  }

  const email = searchParams.get('email') as string;

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <LogoHeader />
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Register your Account
              </h1>
              <form className="space-y-4 md:space-y-6" action="#">
                <FormInput
                onChange={onChangeCode}
                  label="Your Verification Code"
                  id="password"
                  type="password"
                  name="password"
                  placeholder="1337"
                />
                <button
                  onClick={submitSignup}
                  type="button"
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Sign in
                </button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already have an account?{" "}
                  <a
                    href="/login"
                    className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Log In
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
