import React, { useState } from "react";

interface TabOptionProps {
  selected?: boolean;
  title: string;
  onChange: () => void;
}

export function TabOptions({ selected, title, onChange }: TabOptionProps) {
  return (
    <li className="me-2">
      <button
        onClick={onChange}
        className={
          selected
            ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active bg-gradient-to-br from-purple-600 to-blue-500 dark:text-white"
            : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
        }
      >
        {title}
      </button>
    </li>
  );
}

interface Props {
  options: string[];
  firstSelectedByDefault?: boolean;
  onChange: (option: string) => void;
}

export function TabPicker({
  options,
  firstSelectedByDefault,
  onChange,
}: Props) {
  const [selected, setSelected] = useState( firstSelectedByDefault ? options[0] : '');

  const onSelect = async (opt : string) => {
    setSelected(opt);
    onChange(opt)
  }

  return (
    <>
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        {options.map((option, index) => (
          <TabOptions
            selected={selected === option}
            onChange={() => onSelect(option)}
            title={option}
          />
        ))}
      </ul>
    </>
  );
}
