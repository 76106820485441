import { 
  mdiCloudOutline, 
  mdiDatabase, 
  mdiDatabaseOutline, 
  mdiServerOutline, 
  mdiSkullCrossbones 
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";

export default function ElectricBoxes() {
  const [showBoxes, setShowBoxes] = useState(false); // State to control box visibility
  const [showRightBoxes, setShowRightBoxes] = useState(false); // State for right boxes

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBoxes(true); // Show left and middle boxes after 1 second
    }, 500); 

    const rightBoxesTimer = setTimeout(() => {
      setShowRightBoxes(true); // Show right boxes after 2 seconds
    }, 1000);

    return () => {
      clearTimeout(timer); // Clean up timer on unmount
      clearTimeout(rightBoxesTimer); // Clean up right boxes timer
    };
  }, []);

  return (
    <div className={`flex items-center justify-center h-full bg-transparent mb-32 mt-5`}>
      {/* Left Box */}
      <div className="w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 z-10 ml-4  md:ml-24 lg:ml-32">
        <Icon path={mdiSkullCrossbones} className="text-red-600" size="100%" />
      </div>

      {/* Connecting Line with Surge Animation */}
      <div className="relative w-10 h-1  md:w-24 lg:w-32 bg-gray-900 mx-2 sm:mx-4 overflow-hidden">
        <div className="absolute inset-0 h-full w-full animate-surge bg-gradient-to-r from-transparent via-indigo-400 to-transparent"></div>
      </div>

      {/* Middle Box */}
      <div className={`w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 rounded-md relative z-10 ${showBoxes ? 'fade-in' : 'opacity-0'}`}>
        <Icon path={mdiCloudOutline} className="text-emerald-600" size="100%" />
      </div>

      {/* Lines Connecting from Middle Box to Right Boxes */}
      <div className={`flex flex-col justify-between h-[400px] sm:h-[500px] md:h-[600px] lg:h-[700px] ml-8 sm:ml-16 md:ml-24 lg:ml-32 relative ${showBoxes ? 'fade-in' : 'opacity-0'}`}>
        {/* Top Diagonal Connecting Line */}
        <div className="absolute top-[150px] sm:top-[150px] md:top-[200px] left-[-50px] sm:left-[-100px] md:left-[-125px] w-10 sm:w-24 md:w-32 lg:w-40 h-1 bg-gray-900 overflow-hidden transform -rotate-45 origin-top-left">
          <div
            className="absolute inset-0 h-full w-full animate-surge bg-gradient-to-r from-transparent via-indigo-400 to-transparent"
            style={{ animationDelay: "0.1s" }} // Delay for top line
          ></div>
        </div>

        {/* Middle Horizontal Connecting Line */}
        <div className="absolute top-[50%] left-[-25px] sm:left-[-75px] md:left-[-100px] w-8 sm:w-16 md:w-20 lg:w-24 h-1 bg-gray-900 overflow-hidden">
          <div className="absolute inset-0 h-full w-full animate-surge bg-gradient-to-r from-transparent via-indigo-400 to-transparent"></div>
        </div>

        {/* Bottom Diagonal Connecting Line */}
        <div className="absolute left-[-50px] sm:left-[-100px] md:left-[-125px] bottom-[150px] sm:bottom-[150px] md:bottom-[200px] w-10 sm:w-24 md:w-32 lg:w-40 h-1 bg-gray-900 overflow-hidden transform rotate-45 origin-bottom-left">
          <div
            className="absolute inset-0 h-full w-full animate-surge bg-gradient-to-r from-transparent via-indigo-400 to-transparent"
            style={{ animationDelay: "0.2s" }} // Delay for bottom line
          ></div>
        </div>

        {/* Vertical Stack of Three Boxes to the Right */}
        <div className="ml-2 sm:ml-4 md:ml-6 flex flex-col justify-between h-60 pt-16 lg:pt-0 lg:h-full">
          {/* Top Box with margin for spacing */}
          <div className={`w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 bg-transparent rounded-md relative z-10 mb-2 sm:mb-4 ${showRightBoxes ? 'fade-in' : 'opacity-0'}`}>
            <Icon path={mdiServerOutline} className="text-indigo-800" size="100%" />
          </div>

          {/* Middle Box with margin for spacing */}
          <div className={`mt-2 w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 bg-transparent rounded-md relative z-10 mb-2 sm:mb-4 ${showRightBoxes ? 'fade-in' : 'opacity-0'}`}>
            <Icon path={mdiDatabaseOutline} className="text-indigo-800" size="100%" />
          </div>

          {/* Bottom Box */}
          <div className={`w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 border-red-600 border-2 rounded-md relative z-10 ${showRightBoxes ? 'fade-in' : 'opacity-0'}`}>
            <Icon path={mdiDatabase} className="text-yellow-800" size="100%" />
          </div>
        </div>
      </div>

      <style jsx>{`
        .fade-in {
          animation: fadeIn 1s forwards; // Fade in effect over 1 second
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
}
