import React from "react";

export default function SoftwareAgreement() {
  return (
    <>
      <div className="max-w-3xl mx-auto p-6 bg-slate-800 shadow-md rounded-lg text-gray-300">
        <h1 className="text-2xl font-bold mb-4">
          Software Agreement for ThreatScope
        </h1>
        <p className="text-sm text-gray-200 mb-6">Last Updated: 2024-11-12</p>
        <p className="mb-4">Under Construction</p>
      </div>
    </>
  );
}
