import React, {} from "react";


export default function ContactUs() {

  return (
    <>
    <section id='contact' className="contact-section bg-slate-950 text-white py-12">
  <div className="container mx-auto text-center">
    <h2 className="text-3xl font-bold mb-4">Ready to Secure Your Business?</h2>
    <p className="mb-6 text-gray-400">
      Get in touch with our sales team to discuss how we can help you achieve your cybersecurity goals.
    </p>
    <a 
      href="https://forms.gle/Wy9hC1PZ46MVrSqc9" 
      className="contact-button inline-block px-8 py-3 text-lg font-semibold text-white bg-indigo-600 rounded-xl hover:bg-indigo-500 transition duration-300">
      Click Here to Learn More!
    </a>
  </div>
</section>
      </>
  );
}
