import React from "react";
interface Props {
  text: string;
  link: string;
  onClick: () => void
}

export default function NavButton({ text, link, onClick}: Props) {
  return (
    <>
      <a
        href={link}
        className="ml-4 text-white bg-indigo-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        aria-haspopup="true"
        aria-current="page"
        onClick={onClick}
      >
        {text}
      </a>
    </>
  );
}
