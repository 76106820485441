import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import anime from "animejs";
import { useState } from "react";

interface Props {
  items: { title: string; onClick: () => void }[];
  title: string;
}

export default function Dropdown({ title, items }: Props) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const onDropdownClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (callback: () => void) => {
    setDropdownOpen(false);
    callback();
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5  text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={onDropdownClick}
        >
          {title}
          <Icon path={mdiPlus} size={1} />
        </button>
      </div>

      {/* <!--
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  --> */}
      {isDropdownOpen && (
        <div
          id="dropdown-container"
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1" role="none">
            {items.map((item, index) => {
              return (
                <button
                  onClick={() => handleItemClick(item.onClick)}
                  className="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  id={`menu-item-${index}`}
                  tabIndex={index}
                >
                  {item.title}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
