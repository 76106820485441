import { v4 as uuidv4 } from 'uuid';
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";

interface Props {
  onSkip: () => void;
}
export default function Onboarding({ onSkip }: Props) {
  const codeString = `AWSTemplateFormatVersion: '2010-09-09'
Description: |
  This template creates an AWS IAM Role with an inline policy and two AWS managed policies
  attached. It sets the trust policy to permit ThreatScope to assume a the role. Run the following command to create the role.

# aws cloudformation create-stack --stack-name threatscope-scan-role --template-body file://ts-assume.yaml  --capabilities CAPABILITY_NAMED_IAM
# Use the role as input to the ThreatScope scan "arn:aws:iam::<your account>:role/TSScanRole"

Resources:
  TSScanRole:
    Type: AWS::IAM::Role
    Properties:
      AssumeRolePolicyDocument:
        Version: '2012-10-17'
        Statement:
          - Effect: Allow
            Principal:
              AWS: "arn:aws:iam::124355648138:role/Security-Scanner"
            Action: 'sts:AssumeRole'
            Condition:
              StringEquals:
                'sts:ExternalId': 'ts-${uuidv4()}'
      MaxSessionDuration: 43200
      ManagedPolicyArns:
        - 'arn:aws:iam::aws:policy/SecurityAudit'
        - 'arn:aws:iam::aws:policy/job-function/ViewOnlyAccess'
      RoleName: 'TSScanRole'
      Policies:
        - PolicyName: TSScanRoleAdditionalViewPrivileges
          PolicyDocument:
            Version : '2012-10-17'
            Statement:
            - Effect: Allow
              Action:
                - 'account:Get*'
                - 'appstream:Describe*'
                - 'appstream:List*'
                - 'backup:List*'
                - 'bedrock:List*'
                - 'bedrock:Get*'
                - 'cloudtrail:GetInsightSelectors'
                - 'codeartifact:List*'
                - 'codebuild:BatchGet*'
                - 'codebuild:ListReportGroups'
                - 'cognito-idp:GetUserPoolMfaConfig'
                - 'dlm:Get*'
                - 'drs:Describe*'
                - 'ds:Get*'
                - 'ds:Describe*'
                - 'ds:List*'
                - 'dynamodb:GetResourcePolicy'
                - 'ec2:GetEbsEncryptionByDefault'
                - 'ec2:GetSnapshotBlockPublicAccessState'
                - 'ec2:GetInstanceMetadataDefaults'
                - 'ecr:Describe*'
                - 'ecr:GetRegistryScanningConfiguration'
                - 'elasticfilesystem:DescribeBackupPolicy'
                - 'glue:GetConnections'
                - 'glue:GetSecurityConfiguration*'
                - 'glue:SearchTables'
                - 'lambda:GetFunction*'
                - 'logs:FilterLogEvents'
                - 'lightsail:GetRelationalDatabases'
                - 'macie2:GetMacieSession'
                - 'macie2:GetAutomatedDiscoveryConfiguration'
                - 's3:GetAccountPublicAccessBlock'
                - 'shield:DescribeProtection'
                - 'shield:GetSubscriptionState'
                - 'securityhub:BatchImportFindings'
                - 'securityhub:GetFindings'
                - 'ssm:GetDocument'
                - 'ssm-incidents:List*'
                - 'support:Describe*'
                - 'tag:GetTagKeys'
                - 'wellarchitected:List*'
              Resource: '*'
        - PolicyName: TSScanRoleAdditionalViewPrivilegesApiGateway
          PolicyDocument:
            Version : '2012-10-17'
            Statement:
            - Effect: Allow
              Action:
                - 'apigateway:GET'
              Resource: 'arn:aws:apigateway:*::/restapis/*'`;
  return (
    <div className="mx-auto w-2/3">
      <div className="flex justify-between items-center pt-10">
        <h1 className="text-violet-100 text-3xl">Onboarding</h1>
        <button
          className="ml-4 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center m-8"
          onClick={() => onSkip()}
        >
          Next
        </button>
      </div>
      <p className="text-slate-300 text-xl pb-10 w-3/4">
        It seems like it's your first time here. To allow Threatscope to scan
        your infrastructure, please use the following CFN to construct a stack.
      </p>

      <div className="relative">
        <button className="absolute flex flex-row  top-0 right-0 p-2 w-16 h-16">
          {/* <span className='m-1 pb-1 basis-3/4 text-xs'>{language}</span> */}
          <CopyToClipboard text={codeString} onCopy={() => alert("Copied!")}>
            <i
              className="mdi mdi-clipboard-outline text-3xl m-1 hover:text-gray-300 
             bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text"
            />
          </CopyToClipboard>
        </button>
        <SyntaxHighlighter
          className=""
          language={"yaml"}
          style={oneDark}
          wrapLines={true}
          wrapLongLines={true}
          showLineNumbers={false}
          showInlineLineNumbers={false}
        >
          {codeString}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}

