import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import AuthService from "../../service/AuthService";
import { useEffect, useState } from "react";

interface Props {
  authService: AuthService;
}

export default function AppLayout({ authService }: Props) {
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await authService.getUser();
      } catch (error) {
        navigate("/login");
      } finally {
        setLoaded(true);
      }
    };

    checkAuth();
  });

  return (
    <>
      {loaded && (
        <>
          <Sidebar
            authService={authService}
            collapsed={sidebarCollapsed}
            onCollapseButtonClick={() => setSidebarCollapsed(!sidebarCollapsed)}
          />

          <div
            className={`pr-6 dark:border-gray-700 transition-all duration-150 ${
              sidebarCollapsed ? "pl-24" : "pl-72"
            }`}
          >
            <Outlet />
          </div>
        </>
      )}
    </>
  );
}
