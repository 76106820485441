import React, { useEffect, useState } from "react";
import ScanLineGraph from "../tiles/ScanLineGraph";
import AuthService from "../../service/AuthService";
import ScanService from "../../service/ScanService";
import FindingSeverityRadialGraph from "../tiles/FindingSeverityRadialGraph";
import FindingsOverTime from "../tiles/FindingsOverTime";
import { Scan } from "common";
import TopNFailingCompliances from "../tiles/TopNFailingCompliances";
import Loading from "../Loading";
interface Props {
  scanService: ScanService;
  authService: AuthService;
}

export default function Home({ authService, scanService }: Props) {
  const [scans, setScans] = useState(undefined as unknown as Scan[])
  const [lastInfraEnabledScan, setLastInfraEnabledScan] = useState(
    undefined as unknown as Scan
  );

  useEffect(() => {
    if (lastInfraEnabledScan) {
      return;
    }
    scanService.list().then((list) => {
      setScans(list);
      const lastScan = list
        .reverse()
        .find((scan) => scan.Targets.find((t) => t.Type === "InfraScan"));
      if (lastScan) setLastInfraEnabledScan(lastScan);
    });
  });

  if (!scans) {
    return <Loading />
  }
  return (
    <>
      <div className="p-4">
        <h1 className="text-indigo-400 text-4xl">Dashboard</h1>
        <div className="pb-4 rounded-lg dark:border-indigo-700 mt-14">
          <div className="grid grid-cols-3 mx-12 mb-4">
            <ScanLineGraph scans={scans}  />
            <FindingSeverityRadialGraph  scan={lastInfraEnabledScan} />
            <FindingsOverTime scans={scans} />
          </div>
          <div className=" h-50 grid grid-cols-4 justify-center mb-4 rounded space-x-4">
            {lastInfraEnabledScan && (
              <TopNFailingCompliances maxResults={4}
                scan={lastInfraEnabledScan}
              />
            )}
          </div>
          
        </div>
      </div>
    </>
  );
}
