import Icon from "@mdi/react";
import { ReportItem } from "common";
import React from "react";
import { colorCodes } from "../ColorCodes";

interface Props {
  iconPath?: string;
  text: string;
  backgroundColor: string;
  textColor: string;
  borderColor: string;
  className?: string;
  onClick?: () => void;
}

export default function Badge({
  iconPath,
  text,
  backgroundColor,
  textColor,
  borderColor,
  className,
  onClick,
}: Props) {
  const isClickable = !!onClick;

  return (
    <button
      onClick={onClick}
      className={`${backgroundColor} ${textColor} text-xs font-medium inline-flex items-center px-2.5 py-0.5 border ${borderColor} ${className} ${
        isClickable ? "cursor-pointer" : ""
      }`}
      disabled={!isClickable}
    >
      {iconPath && <Icon size={1} path={iconPath} className="mx-2" />}
      {text}
    </button>
  );
}



export function fromReportItem(reportItem: ReportItem): React.ReactNode {
  const info = colorCodes[reportItem.Severity.toString()];

  return (
    <Badge
      text={reportItem.Severity.toString()}
      backgroundColor={info.backgroundColor}
      borderColor={info.borderColor}
      textColor={"text-white"}
      className="rounded"
    />
  );
}
