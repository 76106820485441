import React, { useState } from "react";
import { ReportItem, Scan, Severity } from "common";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiDownload } from "@mdi/js";
import Badge from "../../../Badge";
import { colorCodes } from "../../../../ColorCodes";
import ResultTable from "./ResultTable";

interface Props {
  downloadReport: () => {};
  scan: Scan;
  reportItems: ReportItem[];
  selectedSeverity?: string;
  onNextClick: (last: ReportItem) => void;
  onPreviousClick: (previousReportItem: ReportItem) => void;
  onFocusReportItem: (reportItem: ReportItem) => void;
  onSeverityClick: (severity: any) => void;
  previousButtonDisabled: boolean;
  nextButtonDisabled: boolean;
}

export default function InfrastructureTab({
  scan,
  reportItems,
  selectedSeverity,
  onSeverityClick,
  onNextClick,
  onPreviousClick,
  previousButtonDisabled,
  nextButtonDisabled,
  onFocusReportItem,
  downloadReport,
}: Props) {
  return (
    <>
      <ResultTable
        scan={scan}
        reportItems={reportItems}
        selectedSeverity={selectedSeverity}
        onSeverityClick={onSeverityClick}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        previousButtonDisabled={previousButtonDisabled}
        nextButtonDisabled={nextButtonDisabled}
        onFocusReportItem={onFocusReportItem}
        downloadReport={downloadReport}
      />
    </>
  );
}
