import AuthService from "./AuthService";
import axios from "axios";
import { AWS_COMPLIANCES, ReportItem } from "common";

export default class ReportService {
  uri: String;
  authService: AuthService;
  constructor(authService: AuthService, uri: String) {
    this.uri = uri;
    this.authService = authService;
  }

  async getItems(
    id: number,
    lastToken?: string,
    paginateBackwards?: boolean,
    filters?: { severity?: any; scanType?: any }
  ): Promise<{
    items: ReportItem[];
    hasMore: boolean;
  }> {
    const token = await this.authService.getToken();

    const response = await axios.get(`${this.uri}/report/${id}/items`, {
      params: {
        lastToken,
        paginateBackwards,
        filters,
      },
      headers: {
        Authorization: token as unknown as string,
        "Content-Type": "application/json",
      },
    });

    return {
      items: response.data.items,
      hasMore: response.data.hasMore,
    };
  }

  async get(id: number): Promise<void> {
    const token = await this.authService.getToken();

    const response = await axios.get(`${this.uri}/report/${id}`, {
      headers: {
        Authorization: token as unknown as string,
        "Content-Type": "application/json",
      },
    });

    // Assuming response contains a file URL as data
    const fileUrl = response.data.body; // The file URL
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `${id}-report.json` as string);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async downloadCodescanReport(
    reportId: number,
    targetId: number
  ): Promise<void> {
    const token = await this.authService.getToken();
    const response = await axios.get(
      `${this.uri}/codescan/${reportId}/${targetId}`,
      {
        headers: {
          Authorization: token as unknown as string,
          "Content-Type": "application/json",
        },
      }
    );

    // Assuming response contains a file URL as data
    const fileUrl = response.data.body; // The file URL
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `${reportId}-report.json` as string);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async downloadComplianceReport(
    reportId: number,
    targetId: number,
    complianceFrameworkId: number
  ): Promise<void> {
    const token = await this.authService.getToken();

    const response = await axios.get(
      `${this.uri}/report/${reportId}/${targetId}/${complianceFrameworkId}`,
      {
        headers: {
          Authorization: token as unknown as string,
          "Content-Type": "application/json",
        },
      }
    );

    const compliance = AWS_COMPLIANCES.find(
      (e) => e.id === complianceFrameworkId
    );

    // Assuming response contains a file URL as data
    const fileUrl = response.data.body; // The file URL

    // Trigger the download by creating a link element and clicking it programmatically
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", compliance?.reportFile as string); // You can customize the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
