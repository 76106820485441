import { redirect, useSearchParams } from "react-router-dom";
import AuthService from "../../../service/AuthService";

interface Props {
  authService: AuthService;
  buyService: any; //BuyService
}

const Success = ({ buyService, authService }: Props) => {
  const [searchParams] = useSearchParams();

  if (!searchParams.has("session_id")) {
    redirect("/home");
  }

  const sessionId = searchParams.get("session_id") as string;

  return (
    <>
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        action={`${buyService.url}/create-portal-session`}
        method="POST"
      >
        <input
          type="hidden"
          name="session_id"
          value={sessionId}
        />
        <button type="submit">Manage</button>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Buy
          </button>
        </div>
      </form>
    </>
  );
};

export default Success;
