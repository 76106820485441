export interface Compliance {
  name: string;
  reportFile: string;
  prowlerName?: string;
  id: number;
}

export const AWS_COMPLIANCES: Compliance[] = [
  {
    id: 0,
    name: "AWS Account Security Onboarding",
    prowlerName: "AWS-Account-Security-Onboarding",
    reportFile: "report_aws_account_security_onboarding_aws.csv",
  },
  {
    id: 1,
    name: "AWS Audit Manager Control Tower Guardrails",
    prowlerName: "AWS-Audit-Manager-Control-Tower-Guardrails",
    reportFile: "report_aws_audit_manager_control_tower_guardrails_aws.csv",
  },
  {
    id: 2,
    prowlerName: "AWS-Foundational-Security-Best-Practices",
    name: "AWS Foundational Security Best Practices",
    reportFile: "report_aws_foundational_security_best_practices_aws.csv",
  },
  {
    id: 3,
    name: "AWS Foundational Technical Review",
    prowlerName: "AWS-Foundational-Technical-Review",
    reportFile: "report_aws_foundational_technical_review_aws.csv",
  },
  {
    id: 4,
    name: "AWS Well Architected Framework - Reliability",
    prowlerName: "AWS-Well-Architected-Framework-Reliability-Pillar",
    reportFile:
      "report_aws_well_architected_framework_reliability_pillar_aws.csv",
  },
  {
    id: 5,
    name: "AWS Well Architected Framework - Security",
    prowlerName: "AWS-Well-Architected-Framework-Security-Pillar",
    reportFile: "report_aws_well_architected_framework_security_pillar_aws.csv",
  },
  {
    id: 6,
    name: "CIS 1.4",
    reportFile: "report_cis_1.4_aws.csv",

    prowlerName: "CIS-1.4",
  },
  {
    id: 7,
    name: "CIS 1.5",
    reportFile: "report_cis_1.5_aws.csv",
    prowlerName: "CIS-1.5",
  },
  {
    id: 8,
    name: "CIS 2.0",
    reportFile: "report_cis_2.0_aws.csv",
    prowlerName: "CIS-2.0",
  },
  {
    id: 9,
    name: "CIS 3",
    reportFile: "report_cis_3.0_aws.csv",
    prowlerName: "CIS-3.0",
  },
  {
    id: 10,
    name: "CISA AWS",
    reportFile: "report_cisa_aws.csv",
    prowlerName: "CISA",
  },
  {
    id: 11,
    name: "ENS RD2022",
    reportFile: "report_ens_rd2022_aws.csv",
    prowlerName: "ENS-RD2022",
  },
  {
    id: 12,
    name: "Fedramp Low Revision 4",
    reportFile: "report_fedramp_low_revision_4_aws.csv",
    prowlerName: "FedRAMP-Low-Revision-4",
  },
  {
    id: 13,
    name: "Fedramp Moderate Revision 4",
    reportFile: "report_fedramp_moderate_revision_4_aws.csv",
    prowlerName: "FedRamp-Moderate-Revision-4",
  },
  {
    id: 14,
    name: "FFIEC",
    reportFile: "report_ffiec_aws.csv",
    prowlerName: "FFIEC",
  },
  {
    id: 15,
    name: "GDPR",
    reportFile: "report_gdpr_aws.csv",
    prowlerName: "GDPR",
  },
  {
    id: 16,
    name: "GXP 21 CFR Part 11",
    reportFile: "report_gxp_21_cfr_part_11_aws.csv",
    prowlerName: "GxP-21-CFR-Part-11",
  },
  {
    id: 17,
    name: "GXP EU Annex 11",
    reportFile: "report_gxp_eu_annex_11_aws.csv",
    prowlerName: "GxP-EU-Annex-11",
  },
  {
    id: 18,
    name: "HIPPAA",
    reportFile: "report_hipaa_aws.csv",
    prowlerName: "HIPAA",
  },
  {
    id: 19,
    name: "ISO 27001 2013",
    reportFile: "report_iso27001_2013_aws.csv",
    prowlerName: "ISO27001-2013",
  },
  {
    id: 22,
    name: "Mitre Attack",
    reportFile: "report_mitre_attack_aws.csv",
    prowlerName: "MITRE-ATTACK",
  },
  {
    id: 23,
    name: "NIST 800 53 Revision 2",
    reportFile: "report_nist_800_171_revision_2_aws.csv",
    prowlerName: "NIST-800-171-Revision-2",
  },
  {
    id: 24,
    name: "NIST 800 53 Revision 4",
    reportFile: "report_nist_800_53_revision_4_aws.csv",
    prowlerName: "NIST-800-171-Revision-4",
  },
  {
    id: 25,
    name: "NIST 800 53 Revision 5",
    reportFile: "report_nist_800_53_revision_5_aws.csv",
    prowlerName: "NIST-800-171-Revision-5",
  },
  {
    id: 26,
    name: "NIST CSF 1.1",
    reportFile: "report_nist_csf_1.1_aws.csv",
    prowlerName: "NIST-CSF-1.1",
  },
  {
    id: 27,
    name: "PCI 3.2.1",
    reportFile: "report_pci_3.2.1_aws.csv",

    prowlerName: "PCI-3.2.1",
  },
  {
    id: 28,
    name: "RBI Cyber Security Framework",
    reportFile: "report_rbi_cyber_security_framework_aws.csv",
    prowlerName: "RBI-Cyber-Security-Framework",
  },
  {
    id: 29,
    name: "SOC2 AWS",
    reportFile: "report_soc2_aws.csv",

    prowlerName: "SOC2",
  },
];
