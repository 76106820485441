import React, { useState } from "react";
import FormInput from "../../../FormInput";
import { mdiCloseCircle } from "@mdi/js";
import Icon from "@mdi/react";

interface Props {
  errors?: any[];
  onChangeScannerRole: (event: any) => void;
  onClickScanPublicEndpointsToggle: (value: boolean) => void;
  onChangeExternalId: (value: any) => void;
  onClose: () => void;
}

export default function InfrastructureScan({
  onChangeScannerRole,
  onClickScanPublicEndpointsToggle,
  onChangeExternalId,
  onClose,
  errors,
}: Props) {
  const [scanPublicEndpoints, setScanPublicEndpoints] = useState(false);

  const onCheckboxClick = () => {
    const newValue = !scanPublicEndpoints;
    onClickScanPublicEndpointsToggle(newValue);
    setScanPublicEndpoints(newValue);
  };

  return (
    <>
      <div className="grid grid-cols-2 items-center">
        <h3 className="text-3xl font-bold dark:text-white">
          Infrastructure Scan
        </h3>
        <div className="flex justify-end">
          <button
            onClick={() => onClose()}
            className="mx-auto flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-red-400 sm:mx-0 "
          >
            <Icon path={mdiCloseCircle} size={1.0} color={""} />
          </button>
        </div>
      </div>

      <FormInput
        onChange={onChangeScannerRole}
        type="text"
        id="role"
        name="role"
        label="Scanner Role ARN"
        placeholder="arn:aws:iam::1337:role/ExampleRole"
        error={errors && errors.find((e) => e.fieldName === "Role")}
      />

      <FormInput
        onChange={onChangeExternalId}
        type="text"
        id="externalId"
        name="externalId"
        label="External Id"
        placeholder="threatscope-scanner"
        error={errors && errors.find((e) => e.fieldName === "ExternalId")}
      />

      <div className="">
        <label className="inline-flex items-center cursor-pointer dark:text-gray-400">
          <input
            type="checkbox"
            value={"ScanPublicEndpoints"}
            className="sr-only peer mx-4"
            checked={scanPublicEndpoints}
            onClick={onCheckboxClick}
          />
          <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 dark:text-gray-400 peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            Scan Public Endpoints
          </span>
        </label>
      </div>
    </>
  );
}
