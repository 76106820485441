import React, { useEffect, useState } from "react";
import SubscriptionService from "../../../service/SubscriptionService";
import AuthService from "../../../service/AuthService";
import Loading from "../../Loading";
import { useNavigate } from "react-router-dom";
import { CognitoAttributesAttribute } from "amazon-cognito-identity-js";
import Card from "../../Card";
import Icon from "@mdi/react";
import {
  mdiAccount,
  mdiEarth,
  mdiMedal,
} from "@mdi/js";

interface Props {
  subscriptionService: SubscriptionService;
  authService: AuthService;
}

function Account({ subscriptionService, authService }: Props) {
  const [attributes, setAttributes] = useState(
    undefined as unknown as CognitoAttributesAttribute[]
  );
  const navigate = useNavigate();

  const loadAttributes = () => {
    authService
      .getUserAttributes(() => {
        authService.signOut();
        navigate("/login");
        return {};
      })
      .then(async (attributes) =>
        setAttributes(attributes as CognitoAttributesAttribute[])
      );
  };

  useEffect(() => {
    if (!attributes) {
      loadAttributes();
    }
  }, [attributes]);

  if (!attributes) {
    return <Loading />;
  }

  return (
    <>
      <div className="grid grid-cols-3 gap-4 justify-between text-white pt-32">
        <div className="ml-32">
          <div className="w-16 h-16 flex items-center justify-center rounded-full bg-purple-600">
            <span className="text-white text-xl"> B</span>
          </div>
          <h1 className="text-white my-4 text-xl">
            {attributes.find((attr) => attr.Name === "email").Value}
          </h1>
          <div className="flex flex-col items-start space-y-4">
            <button className="text-gray-400 hover:text-blue-300">Personal Information</button>
            <button className="text-gray-400 hover:text-blue-300">Payment Plan</button>
            <button className="text-gray-400 hover:text-blue-300">Preferences</button>
          </div>
        </div>
        <div className="">
          <p className="text-white text-2xl pb-4">Account Information</p>

          <div className="grid grid-cols-2 gap-2">
            <Card
              title="Email"
              description={
                attributes.find((attr) => attr.Name === "email").Value
              }
              icon={<Icon path={mdiAccount} size={1} className="ml-4" />}
            />

            <Card
              title="Language"
              description="English"
              icon={<Icon path={mdiEarth} size={1} className="ml-4" />}
            />

            <Card
              title="Subscription"
              description={"Business"}
              icon={<Icon path={mdiMedal} size={1} className="ml-4" />}
            />
          </div>
          <div className="mt-8 space-x-4">
            <button
              type="button"
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            >
              Delete My Account
            </button>
            <button
              type="button"
              className="focus:outline-none text-white bg-blue-400 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-400 dark:hover:bg-blue-700 dark:focus:ring-blue-900"
            >
              Download My Data
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Account;
