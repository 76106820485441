export const colorCodes = {
    Critical: {
      backgroundColor: "bg-red-500",
      textColor: "text-red-500",
      borderColor: "border-red-900",
      asRGB: 'rgb(239 68 68)',
      asHex: '#EF4444'
    },
    High: {
      backgroundColor: "bg-orange-500",
      textColor: "text-orange-500",
      borderColor: "border-orange-900",
      asRGB: 'rgb(249 115 22)',
      asHex: '#F97316'
    },
    Medium: {
      backgroundColor: "bg-yellow-500",
      textColor: "text-yellow-500",
      borderColor: "border-yellow-900",
      asRGB: 'rgb(234 179 8)',
      asHex: '#EAB308'
    },
    Low: {
      backgroundColor: "bg-green-500",
      textColor: "text-green-500",
      borderColor: "border-green-900",
      asRGB: 'rgb(34 197 94)',
      asHex: '#22C55E'
    },
    Log: {
      backgroundColor: "bg-blue-500",
      textColor: "text-blue-500",
      borderColor: "border-blue-900",
      asRGB: 'rgb(59 130 246)',
      asHex: '#3B82F6'
    },
    Informational: {
      backgroundColor: "bg-white",
      textColor: "text-white",
      borderColor: "border-white",
      asRGB: 'rgb(255 255 255)',
      asHex: '#CCCCCC'
    }
  };
  