import React, { useState } from "react";
import AuthService from "../../../service/AuthService";
import FormInput from "../../FormInput";
import LogoHeader from "../../LogoHeader";
import { useSearchParams, useNavigate } from "react-router-dom";

interface Props {
  authService: AuthService;
}
export default function ConfirmPassword({ authService }: Props) {
  const onClickSubmit = (event: any) => {
    authService.confirmPassword(
      email,
      code,
      password,
      () => navigate("/login"),
      () => {}
    );
  };

  const onChangeCode = (event: any) => {
    setCode(event.target.value);
  };

  const onChangePassword = (event: any) => {
    setPassword(event.target.value);
  };

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // You somehow got to this page without being redirected? Send em back.
  if (!searchParams.has("email")) {
    navigate("/login");
  }

  const email = searchParams.get("email") as string;

  return (
    <section className="bg-gray-50 dark:bg-gray-950">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <LogoHeader />
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Forgot Password
            </h1>
            <form className="space-y-4 md:space-y-6" action="#">
              <FormInput
                onChange={onChangeCode}
                label="Verification Code"
                id="code"
                type="text"
                name="code"
                placeholder="1337"
              />

              <FormInput
                onChange={onChangePassword}
                label="New Password"
                id="password"
                type="password"
                name="passowrd"
                placeholder="••••••••"
              />

              <button
                onClick={onClickSubmit}
                type="button"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
              >
                Update Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
