import React from "react";

interface Props {
  disabled?: boolean;
  id: string;
  name: string;
  label: string;
  error?: { errorMessage: string };
  type: "email" | "password" | "text";
  placeholder?: string;
  onChange?: (event: any) => void;
}

export default function FormInput({
  disabled,
  error,
  id,
  name,
  label,
  type,
  placeholder,
  onChange,
}: Props) {
  return (
    <>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-indigo-300"
      >
        {label}
      </label>
      <input
        disabled={disabled}
        onChange={onChange}
        type={type}
        name={name}
        id={id}
        className={`bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 
          ${disabled ? 'bg-gray-200 border-gray-400 text-gray-500 cursor-not-allowed  dark:bg-gray-950 dark:border-gray-700 dark:text-gray-700' : ''}`}
        placeholder={placeholder}
      />
      {error && (
        <p className="text-red-500 text-xs italic">{error.errorMessage}</p>
      )}
    </>
  );
}
