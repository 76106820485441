import { ApexOptions } from "apexcharts";
import { Scan } from "common"; // Ensure this import has the correct path for your Scan type
import * as React from "react";
import Chart from "react-apexcharts";
import { colorCodes } from "../../ColorCodes";

interface Props {
  scans: Scan[];
}

export default function FindingsOverTime({ scans }: Props) {
  // Initialize an object to hold counts for each severity and month
  const severityCounts = {
    Low: Array(8).fill(0),
    Log: Array(8).fill(0),
    Informational: Array(8).fill(0),
    Medium: Array(8).fill(0),
    High: Array(8).fill(0),
    Critical: Array(8).fill(0),
  };

  const today = new Date();
  const currentMonth = today.getMonth();
  const monthsToConsider = 8; // Last 8 months including the current one

  // Loop through the scans and categorize them by severity and month
  scans.forEach((scan) => {
    const scanDate = new Date(scan.CreatedAt);
    const scanMonth = scanDate.getMonth();
    const monthDifference = currentMonth - scanMonth;

    // Count only if the scan is within the last 8 months
    if (monthDifference >= 0 && monthDifference < monthsToConsider) {
      const monthIndex = monthsToConsider - 1 - monthDifference; // Calculate the index

      // Check the Digest object for severities
      if (scan.Digest) {
        Object.keys(scan.Digest).forEach((severity) => {
          if (severityCounts[severity] !== undefined) {
            // Increment the count based on the value in the Digest
            severityCounts[severity][monthIndex] += scan.Digest[severity];
          }
        });
      }

      // If there's no Digest, count the Targets as before
      scan.Targets.forEach((target) => {
        const scanType = target.Type; // Access the Type from each Target
        if (severityCounts[scanType] !== undefined) {
          severityCounts[scanType][monthIndex] += 1; // Increment the count for the respective severity
        }
      });
    }
  });

  // Prepare data for the chart
  const data = Object.keys(severityCounts).map((severity) => ({
    name: severity,
    data: severityCounts[severity],
  }));

  const state: { options: ApexOptions; series: ApexAxisChartSeries } = {
    series: data,
    options: {
      legend: {
        show: true,
        labels: {
          colors: '#fff'
        }
              },
      yaxis: {
        show: true,
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: '#fff'
          }
        },
        
        
      },
      chart: {
        toolbar: {
          show: false,
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        labels: {
          style: {
            colors: '#fff'
          }
        },
        categories: [
          "8 months ago",
          "7 months ago",
          "6 months ago",
          "5 months ago",
          "4 months ago",
          "3 months ago",
          "2 months ago",
          "1 month ago",
          "Current Month",
        ],
      },
      colors: Object.keys(severityCounts).map( sev => colorCodes[sev].asHex)
    },
  };

  return (
    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-sm dark:border-gray-800 rounded-lg bg-gradient-to-bl from-blue-900 from-1% via-gray-900 via-30% to-gray-900 to-90% shadow-sm shadow-gray-800">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-400">
        Scan Results over Last 8 Months
      </h5>
      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
        Counts all Infrascans to show trends of Issues detected by the system.
      </p>

      <Chart
        options={state.options}
        series={state.series}
        type="line"
        height={350}
      />
    </div>
  );
}
