import { Writable } from "stream";
import DataAdapter from "./DataAdapter";
import { parse as jsonParse } from "jsonstream"; // JSON parsing library
import { ReportItem } from "./ReportItem";
import { randomUUID } from "crypto";

export class AshAdapter implements DataAdapter {
  parser: any;

  constructor() {
    this.parser = jsonParse("*.model.*.data.matches.*");
  }

  getPipe() {
    return this.parser as Writable;
  }

  getRisk(risk: string) {
    return {
      Critical: 10,
      High: 8,
      Medium: 5,
      Low: 3,
      Log: 1,
    }[risk];
  }

  onDataIngest(customerId: string, reportId: number, targetId: number, data: { [x: string]: any }){
    if (!data || Object.keys(data).length === 0) {
      return;
    }
    const reportItem: ReportItem = {
      CustomerAndReportId: `${customerId}#${reportId}`,
      CustomerReportIdAndScanType: `${customerId}#${reportId}#Code`,
      CustomerId: customerId,
      ReportId: reportId,
      TargetId: targetId,
      ResourceIdentifier: data["vulnerability"]["id"],
      ResourceType: data["vulnerability"]["namespace"],
      Id: randomUUID(),
      Title: data["artifact"]["name"],
      Description: data["vulnerability"]["description"],
      Severity: data["vulnerability"]["severity"],
      RiskScore: this.getRisk(data["vulnerability"]["severity"]) || 0,
      Remediation: "",
      Cves: [],
      DetectedAt: new Date().getTime(),
      status: "Open",
      context: data["vulnerability"]["dataSource"],
    };
    return reportItem;
  }
}
