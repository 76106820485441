import { mdiRobotOutline, mdiShieldAlert } from "@mdi/js";
import Icon from "@mdi/react";
import { AWS_COMPLIANCES, ReportItem } from "common";
import React from "react";
import "@mdi/font/css/materialdesignicons.min.css"; // Allows us to use icon with Gradient
import Badge, { fromReportItem } from "../../../Badge";
import { colorCodes } from "../../../../ColorCodes";

interface Props {
  reportItem: ReportItem;
  onClick: (e: any) => void;
  onEnrichRemediationWithAi: (r: ReportItem) => void;
  buttonText: string;
}

export default function ReportItemDetailModal({
  reportItem,
  onClick,
  onEnrichRemediationWithAi,
  buttonText,
}: Props) {
  let affectedCompliance = undefined;

  if (reportItem.ComplianceImpact && reportItem.ComplianceImpact.length > 0) {
    try {
      affectedCompliance = JSON.parse(reportItem.ComplianceImpact);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* <!--
          Background backdrop, show/hide based on modal state.
      
          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        --> */}
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          {/* <!--
              Modal panel, show/hide based on modal state.
      
              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            --> */}
          <div className="mt-20 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  w-1/2">
            <div className="bg-gray-700 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div
                  className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${colorCodes[reportItem.Severity.toString()].backgroundColor} sm:mx-0 sm:h-10 sm:w-10`}
                >
                  <Icon path={mdiShieldAlert} size={1.5} color={""} />
                </div>
                <div className="absolute right-6 text-gray-400 pt-2">
                  Threat Detected at:{" "}
                  {new Date(reportItem.DetectedAt).toUTCString()}
                </div>
                <div className="mt-5 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <div className="mt-2">{fromReportItem(reportItem)}</div>
                  <div>
                    {reportItem.AccountId && reportItem.Region && (
                      <Badge
                        text={`${reportItem.AccountId} ${reportItem.Region}`}
                        backgroundColor={
                          "bg-gradient-to-br from-green-500 to-emerald-500"
                        }
                        textColor={"text-white"}
                        borderColor={"border-green-600"}
                        className="rounded mt-4"
                      />
                    )}

                    <h3
                      className="mt-4 text-xl font-semibold leading-6 text-gray-300"
                      id="modal-title"
                    >
                      {reportItem.Title}
                    </h3>
                  </div>
                  <div className="mt-2">
                    <br></br>
                    <p className="text-sm text-gray-100">
                      Vulnerability detected in Region: {reportItem.Region}
                    </p>
                    <p className="text-sm text-gray-100">
                      {reportItem.Description}
                    </p>
                    <span className="inline-flex mt-5">
                      <Badge
                        text={reportItem.ResourceType}
                        backgroundColor={"bg-indigo-400"}
                        textColor={"text-white"}
                        borderColor={"border-indigo-400"}
                        className="rounded-l-md px-3 py-2" // Adjust padding as needed
                      />
                      <Badge
                        text={reportItem.ResourceIdentifier}
                        backgroundColor={"bg-gray-500"}
                        textColor={"text-white"}
                        borderColor={"border-transparent"}
                        className="rounded-r-md px-3 py-2" // Match padding to the first Badge
                      />
                    </span>
                    <div className="my-8 text-white">
                      <div className="flex items-center pb-4">
                        <h4 className="text-xl pb-4 mt-2">
                          Recommended Remediation
                        </h4>

                        {/* <button
                          onClick={() => onEnrichRemediationWithAi(reportItem)}
                          title="Enrich with Claude Sonnet"
                          className="inline-flex h-10 bg-gradient-to-br from-purple-200 to-violet-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm ml-2 px-2 text-center my-1 text-transparent bg-clip-text"
                        >
                          <i className="mdi mdi-auto-fix text-2xl" />
                        </button> */}
                      </div>
                      <div>{reportItem.Remediation}</div>
                    </div>

                    <div>
                      {affectedCompliance ? (
                        <>
                          <h3
                            className="text-lg font-semibold leading-6 text-indigo-300"
                            id="modal-title"
                          >
                            Affected Compliance
                          </h3>
                          <div className="relative overflow-x-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                  <th scope="col" className="px-6 py-3">
                                    Framework{" "}
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                    Results
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(affectedCompliance).map((k) => {
                                  const listOfResults = affectedCompliance[
                                    k
                                  ] as string[];

                                  const compliance = AWS_COMPLIANCES.find(
                                    (c) => c.prowlerName === k
                                  );

                                  if (!compliance) {
                                    return <></>;
                                  }

                                  return (
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {compliance?.name}
                                      </td>
                                      <td className="px-6 py-4 block space-y-5">
                                        {listOfResults.map((result) => (
                                          <Badge
                                            text={result}
                                            backgroundColor={"bg-gray-500"}
                                            textColor={"text-white"}
                                            borderColor={"border-gray-500"}
                                            className="rounded-xl mx-1"
                                          />
                                        ))}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <h5 className="text-gray-200 py-4">Additional Context</h5>
                    <p className="text-gray-400">{reportItem.context}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-900 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-gradient-to-br from-purple-600 to-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                onClick={onClick}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
