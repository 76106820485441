import { Writable } from "stream";
import DataAdapter from "./DataAdapter";
import { parse as jsonParse } from "jsonstream"; // JSON parsing library
import { ReportItem } from "./ReportItem";
import { randomUUID } from "crypto";

export class ProwlerAdapter implements DataAdapter {
  parser: any;

  constructor() {
    this.parser = jsonParse("*");
  }

  getPipe() {
    return this.parser as Writable;
  }

  onDataIngest(customerId: string, reportId: number, targetId: number, data: { [x: string]: any }){
    const reportItem : ReportItem = {
      CustomerAndReportId: `${customerId}#${reportId}`,
      CustomerReportIdAndScanType: `${customerId}#${reportId}#Infrastructure`,
      CustomerId: customerId,
      TargetId: targetId,
      ReportId: reportId,
      ResourceIdentifier: data['resources'][0]['uid'],
      ResourceType: data['resources'][0]['group']['name'],
      AccountId: data['cloud']['account']['uid'],
      Region: data['cloud']['region'],
      Id: randomUUID(),
      Title: `${data['finding_info']['title']}`,
      Description: `${data['status_detail']}`,
      Severity: data['severity'],
      RiskScore: data['severity_id'],
      ComplianceImpact: `${JSON.stringify(data['unmapped']['compliance'])}`,
      Remediation: `${data['remediation']['desc']}. ${data['remediation']['references'][0]}`,
      Cves: [],
      DetectedAt: new Date(data['finding_info']['created_time']).getTime(),
      status: data['status'],
      context: `${data['risk_details']}`
    }
   return reportItem;
  }
}
