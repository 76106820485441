import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../service/AuthService";
import FormInput from "../../FormInput";
import LogoHeader from "../../LogoHeader";

interface Props {
  authService: AuthService;
}

export default function ForgotPassword({ authService }: Props) {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const onClickSubmit = (event: any) => {
    authService.forgotPassword(
      email,
      (data: any) => {
        navigate(encodeURI(`/confirm-password?email=${email}`));
      },
      (err) => console.error(err)
    );
  };

  const onChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-950">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <LogoHeader />
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Forgot Password
            </h1>
            <form className="space-y-4 md:space-y-6" action="#">
              <FormInput
                onChange={onChangeEmail}
                label="Your Email"
                id="email"
                type="email"
                name="email"
                placeholder="kermit@sesamestreet.com"
              />
              <button
                onClick={onClickSubmit}
                type="button"
                                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-blue-300 dark:focus:ring-blue-800"
              >
                Send Password Reset
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
