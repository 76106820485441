import React, { useState } from "react";
import { ReportItem, Scan, Severity } from "common";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiDownload } from "@mdi/js";
import Badge from "../../../Badge";
import { colorCodes } from "../../../../ColorCodes";

interface Props {
  downloadReport?: () => {};
  scan: Scan;
  reportItems: ReportItem[];
  selectedSeverity?: string;
  onNextClick: (last: ReportItem) => void;
  onPreviousClick: (previousReportItem: ReportItem) => void;
  onFocusReportItem: (reportItem: ReportItem) => void;
  onSeverityClick: (severity: any) => void;
  previousButtonDisabled: boolean;
  nextButtonDisabled: boolean;
}

export default function ResultTable({
  scan,
  reportItems,
  selectedSeverity,
  onSeverityClick,
  onNextClick,
  onPreviousClick,
  previousButtonDisabled,
  nextButtonDisabled,
  onFocusReportItem,
  downloadReport,
}: Props) {
  const [columnHeaders] = useState([
    { id: "Title", title: "Title", transformationFunc: (s) => s },
    {
      id: "Severity",
      title: "Severity",
      transformationFunc: (s) => (
        <Badge
          key={s}
          text={s}
          backgroundColor={"bg-gray-800"}
          textColor={colorCodes[s]?.textColor || "text-gray-500"}
          borderColor={colorCodes[s]?.borderColor || "border-gray-500"}
          className="rounded"
        />
      ),
    },
    { id: "ResourceIdentifier", title: "Id", transformationFunc: (s) => s },
    {
      id: "DetectedAt",
      title: "Finding Date",
      transformationFunc: (s) => new Date(s).toUTCString(),
    },
    { id: "Description", title: "Description", transformationFunc: (s : string) => s.length > 80 ? s.substring(0, 80)+ '...' : s },
  ]);

  return (
    <>
      {scan.Targets.filter((t) => t.Type === "InfraScan").map(
        (target, index) => (
          <React.Fragment key={index}>
            <div className="py-4 w-full flex justify-between items-center">
  <span className="space-x-2">
    <span className="bg-gray-900 text-gray-200 font-semibold py-2 px-4 border border-gray-800 rounded mr-4">
      Filter by:
    </span>
    {scan.Digest &&
      Object.keys(colorCodes)
        .filter((e) => isNaN(Number(e)))
        .map((severity) => (
          <Badge
            key={severity}
            text={`${severity}`}
            backgroundColor={"bg-gray-800"}
            textColor={
              severity === selectedSeverity
                ? colorCodes[severity].textColor
                : "text-gray-500"
            }
            borderColor={
              severity === selectedSeverity
                ? colorCodes[severity].borderColor
                : "border-gray-500"
            }
            onClick={() => onSeverityClick(severity)}
            className="rounded"
          />
        ))}
  </span>

  {downloadReport && (
    <button
      className="inline-flex h-10 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-2 ml-auto"
      onClick={downloadReport}
    >
      Download Your Report
      <Icon
        path={mdiDownload}
        className={"pb-3 text-white"}
        size={1.5}
      />
    </button>
  )}
</div>

            <table className="table w-full">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th></th>
                  {columnHeaders.map((header) => (
                    <th key={header.id} className="max-w-xs">
                      {header.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="font-medium text-sm text-gray-900 dark:text-white">
                {reportItems.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={rowIndex % 2 === 1 ? "bg-gray-800" : ""}
                  >
                    <td>
                      <button
                        className="text-indigo-600"
                        onClick={() => onFocusReportItem(row)}
                      >
                        <Icon path={mdiChevronRight} size={1} />
                      </button>
                    </td>
                    {columnHeaders.map((column, columnIndex) => (
                      <td
                        key={`${rowIndex}-${columnIndex}`}
                        className="max-w-xs whitespace-normal overflow-hidden text-ellipsis"
                      >
                        {column.transformationFunc(row[column.id])}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex py-4 right-10 absolute">
              <button
                disabled={previousButtonDisabled}
                onClick={() => onPreviousClick(reportItems[0])}
                className={
                  !previousButtonDisabled
                    ? "flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-100 bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-indigo-300 dark:focus:ring-indigo-800 rounded-lg"
                    : "flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                }
              >
                Previous
              </button>

              <button
                disabled={nextButtonDisabled}
                onClick={() => onNextClick(reportItems[reportItems.length - 1])}
                className={
                  !nextButtonDisabled
                    ? "flex items-center justify-center px-3 h-8 ms-3 text-sm font-medium text-gray-100 bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-indigo-300 dark:focus:ring-indigo-800 rounded-lg"
                    : "flex items-center justify-center px-3 h-8 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                }
              >
                Next
              </button>
            </div>
          </React.Fragment>
        )
      )}
    </>
  );
}
