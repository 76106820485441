import React from "react";

export default function Loading() {
  return (
    <>

    <div className="loader text-gray-500">Loading...</div>
    
    
    </>
  );
}
