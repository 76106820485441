import React from "react";
interface Props {
  text: string;
  link: string
}

export default function CtaButton({ text, link }: Props) {
  return (
    <>
      <a
        href={link}
        className="ml-4 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        id="user-menu-button"
        aria-haspopup="true"
        aria-current="page"
      >
        {text}
      </a>
    </>
  );
}
