import {
  DynamoDBClient,
  GetItemCommand,
  QueryCommand,
} from "@aws-sdk/client-dynamodb";
import {
  DynamoDBDocumentClient,
  PutCommand,
  UpdateCommand,
} from "@aws-sdk/lib-dynamodb";
import { unmarshall } from "@aws-sdk/util-dynamodb";
import { ComplianceResult, Scan } from "./Scan";
import { S3 } from "aws-sdk";

export default class ScanController {
  table: string;
  documentClient: DynamoDBDocumentClient;
  s3Client: S3;

  constructor(dynamoDbClient: DynamoDBClient, table: string) {
    this.documentClient = DynamoDBDocumentClient.from(dynamoDbClient);
    this.table = table;
    this.s3Client = new S3();
  }

  get = async (
    customerId: string,
    reportId: string
  ): Promise<Scan | undefined> => {
    // If validation passes, proceed with DynamoDB and SQS operations
    const command = new GetItemCommand({
      TableName: this.table,
      Key: {
        CustomerId: { S: customerId },
        ReportId: { N: reportId },
      },
    });

    const response = await this.documentClient.send(command);
    if (!response || !response.Item) {
      return;
    }
    const scan = this.obfuscate(unmarshall(response.Item) as Scan);

    if (!scan) {
      return;
    }

    return scan;
  };

  getNumberOfReports = async (customerId: string) => {
    const result = await this.getReports(customerId, {
      Select: "COUNT",
    });
    return result.Count || 0;
  };

  getReports = async (
    customerId: string,
    additionalOperations?: { [k: string]: string }
  ) => {
    const result = await this.documentClient.send(
      new QueryCommand({
        TableName: this.table,
        KeyConditionExpression: "CustomerId = :customerId",
        ExpressionAttributeValues: {
          ":customerId": { S: customerId },
        },
        ...additionalOperations,
      })
    );

    return {
      ...result,
      Items: result.Items?.map((i) => unmarshall(i)).map((i) =>
        this.obfuscate(i as Scan)
      ),
    };
  };

  create = async (scan: Scan) => {
    // If validation passes, proceed with DynamoDB and SQS operations
    await this.documentClient.send(
      new PutCommand({
        TableName: this.table,
        Item: scan,
      })
    );
  };

  markAsFinished = async (scan: Scan) => {
    return await this.documentClient.send(
      new UpdateCommand({
        TableName: this.table,
        Key: {
          CustomerId: scan.CustomerId,
          ReportId: scan.ReportId,
        },
        UpdateExpression: `SET FinishedAt = :finishedAt`,
        ExpressionAttributeValues: {
          ":finishedAt": new Date().toISOString(),
        },
      })
    );
  };

  updateCompliance = async (
    scan: Scan,
    targetId: number,
    complianceResults: ComplianceResult[]
  ) => {
    return this.documentClient.send(
      new UpdateCommand({
        TableName: this.table,
        Key: {
          CustomerId: scan.CustomerId,
          ReportId: scan.ReportId,
        },
        UpdateExpression: `SET Targets[${targetId}].ComplianceResults = :complianceResults`,
        ExpressionAttributeValues: {
          ":complianceResults": complianceResults,
        },
      })
    );
  };

  updateDigest = async (scan: Scan, digest: any) => {
    await this.documentClient.send(
      new UpdateCommand({
        TableName: this.table,
        Key: {
          CustomerId: scan.CustomerId,
          ReportId: scan.ReportId,
        },
        UpdateExpression: `SET Digest = :item`,
        ExpressionAttributeValues: {
          ":item": digest,
        },
      })
    );
  };

  obfuscate = (s: Scan) => {
    const maskedScan = s;

    const targets = maskedScan.Targets.map((s) => {
      if (s.Type === "InfraScan") {
        return {
          ...s,
          Role: "************",
          ExternalId: "**********",
        };
      }

      if (s.Type === "CodeScan") {
        return {
          ...s,
          PersonalAccessToken: "**********",
        };
      }

      return;
    });

    return {
      ...s,
      Targets: targets,
    } as Scan;
  };
}
